import React, { useState, Component } from "react";
import LandingPage from "./pages/Landing";
import MarketplacePage from "./pages/Market/Marketplace";
import Zipcodes from "./pages/Zipcodes";
import Contact from "./pages/Contact";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Market from "./pages/Market/MarketInfo.js";
import NotFound from "./static/NotFound";
import Vendorinfo from "./pages/Market/VendorInfo";
import Cart from "./pages/Market/Cart";
import Check from './pages/Check'
import CheckAccount from './pages/CheckAccount'
import Checkout from './pages/Checkout'
import CheckoutSuccess from "./pages/Market/CheckoutSuccess";
import Article from "./pages/NewsArticles/Article";
import ScrollToTop from "./ScrollToTop";

function App() {
  
  return (
    <div>
        <div className="bg-gradient-to-r from-[#088C61] to-[#144444]">
          <p className="text-[#fff] text-sm p-4 text-center">BerriBasket is still in devlopment, if you would like to test our beta version please <a href="/contact" className="underline">contact us</a></p>
        </div>

        <BrowserRouter>
        <ScrollToTop />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Check/>} />
            <Route path="/landing" element={<LandingPage/>} />
            <Route path="/marketplace" element={<MarketplacePage />} />
            <Route path="/zipcodes" element={<Zipcodes />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/account" element={< CheckAccount/>} />
            <Route path="/marketinfo" element={<Market />} />
            <Route path="/vendorinfo" element={<Vendorinfo />} />
            <Route path="/basket" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout/success" element={<CheckoutSuccess />} />
            <Route path="/news/article" element={<Article />} />
          </Routes>

        </BrowserRouter>
    </div>
  );
}

export default App;
