import React from 'react'
import Hamburger from '../comps/Hamburger';
import {Link, useNavigate} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

export default function NotFound() {
  return (
    <div>
   <div className="flex flex-row justify-center bg-[#fbfbfb] border-b">
      <div className="w-1/2">
      <Link to={"/"}>
              <p className="z-auto text-3xl text-[#000] font-bold m-8 mt-[30px] mb-6 cursor-pointer ml-36 lg:ml-8 relative sm:text-2xl sm:mt-[29px]" id="title">
                  Berri<span className="text-[#088C61] font-bold">Basket</span>
                </p>
              </Link>
              </div>
              <div className="w-1/2 lg:flex lg:justify-end -mt-1 lg:mt-0">
                  <Hamburger color={" text-[#000] mr-16 hover:underline underline-offset-4"} bizbtn={" text-[#000] flex items-center"} modal={"-ml-96 w-[30vw] bg-[#ffffff] border rounded-lg absolute mt-10 p-6 z-30"}/>
              </div>
        </div>
    <div className='text-[120px] text-center font-bold mt-[25vh] flex justify-center md:mt-[18vh]'>
      <p className='-rotate-12'>4</p>
      <p className='text-[#088C61] rotate-6'>0</p>
      <p className='rotate-12'>4</p>
      </div>
    <p className='text-[#929292] text-center  mt-2 md:w-[90vw] md:ml-[5vw]'>Woah, Looks like you discovered an unknown page</p>
    <div className='flex justify-center'>
    <Link to={'/'}><button className='text-[#088C61]  mt-5 flex text-center'>Return back home <span className='ml-2 mt-[4px] text-lg'><FiArrowRight/></span></button></Link>
    </div>

    </div>
  )
}
