import React, { useState, useEffect } from "react";
import Hamburger from "../comps/Hamburger";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { MdAccountCircle, MdOutlineHistory } from "react-icons/md";
import Signup from "../comps/Signup";

export default function Account() {
  const [account, setAccount] = useState(true);
  const [orders, setOrders] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [previousFirstName, setPreviousFirstName] = useState("")
  const [previousLastName, setPreviousLastName] = useState("")
  const [previousAddress, setPreviousAddress] = useState("")
  const [previousZipcode, setPreviousZipcode] = useState("")
  const [previousCity, setPreviousCity] = useState("")
  const [editAccountSettings, setEditAccountSettings] = useState(true);
  const [editSave, setEditSave] = useState("Edit Account");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
      "",
      "api/user"
    );

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      mode: "cors",
      credentials: 'include',
      authorization: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    };

    fetch(endpoint, requestOptions)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        if (obj.status === 200) {
          console.log(`Got obj body: ${obj.body}`)
          if (obj.body['first_name']) setFirstName(obj.body['first_name']);
          if (obj.body['last_name']) setLastName(obj.body['last_name']);
          if (obj.body['address_1']) setAddress(obj.body['address_1']);
          if (obj.body['zipcode']) setZipcode(obj.body['zipcode']);
          if (obj.body['city']) setCity(obj.body['city']);
        }
        else {
          console.log(`Fetching user info status error with status code: ${obj.status}`)
        }
      });
  }, [])


  function changeOrder() {
    setAccount(false);
    setOrders(true);
  }
  function changeAccount() {
    setAccount(true);
    setOrders(false);
  }

  function setEditable() {
    setEditAccountSettings(!editAccountSettings);

    if (editSave === "Save") {
      
      //make post request here

      postUserInfo()
      //Set previous values for cancellation
      setPreviousFirstName(firstName)
      setPreviousLastName(lastName)
      setPreviousAddress(address)
      setPreviousZipcode(zipcode)
      setPreviousCity(city)

      setEditSave("Edit Account");

    } else {

      setEditSave("Save");
    }
  }

  function postUserInfo() {
   
    var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
      "",
      "api/user"
    );

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      credentials: 'include',
      authorization: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        address_1: address,
        city: city,
        zipcode: zipcode
      }),
    };

    fetch(endpoint, requestOptions)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        if (obj.status === 200) {
          toast("Account Details Updated");
        }
      });


  }

  function cancellationOnPress() {

    setFirstName(previousFirstName)
    setLastName(previousLastName)
    setAddress(previousAddress)
    setZipcode(previousZipcode)
    setCity(previousCity)
    setEditSave("Edit Account");
    setEditAccountSettings(!editAccountSettings);
  }

  const handleInputEdit = (event, target) => {
    switch (target) {
      case "firstName":
        setFirstName(event.target.value);
        break;
      case "lastName":
        setLastName(event.target.value);
        break;
      case "address":
        setAddress(event.target.value);
        break;
      case "zipcode":
        setZipcode(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      default:
        console.log(`Could not find input target: ${target}`);
        break;
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-center bg-[#fbfbfb]">
        <div className="w-full border">
          <Hamburger />
          <Link to={"/"}>
            <p
              className="z-auto text-3xl text-[#000] font-bold m-8 mt-[30px] mb-6 cursor-pointer ml-[125px] relative sm:ml-[75px] sm:text-2xl sm:mt-[29px]"
              id="title"
            >
              Berri<span className="text-[#088C61] font-bold">Basket</span>
            </p>
          </Link>
        </div>
      </div>

      <div className="flex justify-center ml-36 mr-36 mt-9">
        <div className="w-1/4">
          <div className="w-72 rounded-lg" id="shadow">
            <p className="text-xl font-bold m-3 pt-4 ml-7">Account</p>
            <hr className="border-r mt-0"></hr>
            <p
              className="m-7 text-base cursor-pointer text-[#929292]  mt-6"
              onClick={changeAccount}
            >
              <span className="flex items-center">
                <span className="mr-3 text-2xl">
                  <MdAccountCircle />
                </span>{" "}
                Account info
              </span>
            </p>
            <p
              className="m-7 text-base cursor-pointer text-[#929292]  mb-0 mt-6"
              onClick={changeOrder}
            >
              <span className="flex items-center">
                <span className="mr-3 text-2xl">
                  <MdOutlineHistory />
                </span>{" "}
                Order history
              </span>
            </p>
           
              <div className="mt-6 ml-3">
                <Signup/>
              </div>
          </div>
        </div>

        {account && (
          <div className="w-3/4 ml-10">
            <div className="flex flex-row justify-between justify-center items-center">
              <p className="text-3xl font-bold align-center">Account info</p>
              <div>
                {
                    editSave === 'Save' &&
                  <button
                    id="focusButton"
                    type="button"
                    className="text-white bg-[#000000]  rounded-full m-2 w-32 h-12 ml-5"
                    onClick={cancellationOnPress}
                  >
                    Cancel
                  </button>
                }
                <button
                  id="focusButton"
                  type="button"
                  className="text-white bg-[#088C61]  rounded-full m-2 w-32 h-12"
                  onClick={setEditable}
                >
                  {editSave}
                </button>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex">
                <div>
                  <label  className="relative top-3 left-1 text-[#929292] text-sm">
                    First name
                  </label >
                  <br></br>
                  <input
                    id="focusInput"
                    value={firstName}
                    onChange={(e) => handleInputEdit(e, "firstName")}
                    disabled={editAccountSettings}
                    className="h-12 w-72 mt-4 border-b border-[#D3D3D3] disabled:bg-slate-50 disabled:text-[#64748b80] disabled:border-[#64748b80] disabled:shadow-none"
                    placeholder="John"
                  ></input>
                  <br></br>
                </div>
                <div className="ml-8">
                  <label  className="relative top-3 left-1 text-[#929292] text-sm">
                    Last name
                  </label >
                  <br></br>
                  <input
                    value={lastName}
                    onChange={(e) => handleInputEdit(e, "lastName")}
                    disabled={editAccountSettings}
                    className="h-12 w-72 mt-4 border-b border-[#D3D3D3] disabled:bg-slate-50 disabled:text-[#64748b80] disabled:border-[#64748b80] disabled:shadow-none"
                    placeholder="Doe"
                  ></input>
                  <br></br>
                </div>
              </div>
              <hr className="border-r mt-7 mr-0"></hr>
              <div className="flex justify-start mt-5">
                <div>
                  <label  className="relative top-1.5 left-1 text-[#929292] text-sm">
                    Address
                  </label >
                  <br></br>
                  <input
                    value={address}
                    onChange={(e) => handleInputEdit(e, "address")}
                    disabled={editAccountSettings}
                    className="h-12 w-96 mt-2 border-b border-[#D3D3D3] disabled:bg-slate-50 disabled:text-[#64748b80] disabled:border-[#64748b80] disabled:shadow-none"
                    placeholder="123 maple street"
                  ></input>
                </div>
                <div>
                  <label  className="relative top-1.5 left-8 text-[#929292] text-sm">
                    Zipcode
                  </label >
                  <br></br>
                  <input
                    value={zipcode}
                    onChange={(e) => handleInputEdit(e, "zipcode")}
                    disabled={editAccountSettings}
                    className="ml-8 h-12 w-36 mt-2 border-b border-[#D3D3D3] disabled:bg-slate-50 disabled:text-[#64748b80] disabled:border-[#64748b80] disabled:shadow-none"
                    placeholder="11942"
                  ></input>
                </div>
                <div>
                  <label  className="relative top-1.5 left-1 text-[#929292] text-sm ml-7">
                    City
                  </label >
                  <br></br>
                  <input
                    value={city}
                    onChange={(e) => handleInputEdit(e, "city")}
                    disabled={editAccountSettings}
                    className="ml-8 h-12 w-44 mt-2 border-b border-[#D3D3D3] disabled:bg-slate-50 disabled:text-[#64748b80] disabled:border-[#64748b80] disabled:shadow-none"
                    placeholder="New York"
                  ></input>
                </div>
              </div>
              <p className="mt-8  text-[#929292] text-sm">*Please <Link to={'/contact'}><span className="text-[#088C61]">contact us</span></Link> for further account changes</p>
            </div>
           

                      </div>
        )}

        {orders && (
          <div className="w-3/4 ml-10">
            <p className="text-3xl font-bold">Order history</p>
            <p className=" text-lg text-[#929292] mt-20 text-center -ml-5">
              No orders
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
