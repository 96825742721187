import React, { useRef } from 'react';
import Hamburger from '../comps/Hamburger';
import {Link} from "react-router-dom";
import ContactAccord from "../comps/ContactAccord";
import { FiArrowRight } from "react-icons/fi";
import Footer from "../comps/Footer";
import emailjs from '@emailjs/browser';
import toast, { Toaster } from "react-hot-toast";


export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dncmvp4', 'template_7ii8oih', form.current, 'gI5gUL2nY1dk8M_-s')
      .then((result) => {
          console.log(result.text);
          toast("Thank you, your email sent with profection")
      }, (error) => {
          console.log(error.text);
          toast("Error sending email")

      });
  };
  return (
    <div>
           <Toaster
          toastOptions={{
            error: {
              iconTheme: {
                primary: "#F06747",
                secondary: "white",
              },
            },
          }}
        />

<div className="flex flex-row justify-center bg-[#fbfbfb] border-b">
      <div className="w-1/2">
      <Link to={"/"}>
              <h2 className="z-auto text-3xl text-[#000] font-bold m-8 mt-[30px] mb-6 cursor-pointer ml-36 lg:ml-4 relative sm:text-2xl sm:mt-[29px]" id="title">
                  Berri<span className="text-[#088C61] font-bold">Basket</span>
                </h2>
              </Link>
              </div>
              <div className="w-1/2 lg:flex lg:justify-end -mt-1 mr-4 md:mt-6">
                  <Hamburger color={" text-[#000] mr-16 hover:underline underline-offset-4"} bizbtn={" text-[#000] flex items-center z-[999]"} modal={"-ml-96 w-[30vw] bg-[#ffffff] border rounded-lg absolute mt-10 p-6 z-30"}/>
              </div>
        </div>

        <div className="flex justify-center mt-20 lg:flex-col">
        <div className="w-1/2 lg:w-full lg:p-2">
          <h1 className="text-4xl font-bold ml-36 mt-32 lg:-mt-8 lg:text-center lg:ml-0 lg:text-2xl" id="title">How can we help?</h1>
          <h2 className=" mt-5 text-[#929292] ml-36 w-3/5 lg:mb-8 lg:text-center lg:ml-0 lg:w-full">Contact us by filling out the form and we'll respond within 3-5 business days</h2>
        </div>

        <div className="w-1/2 flex justify-end mb-22 lg:justify-center lg:w-full">
          <form ref={form} onSubmit={sendEmail}>
          <div>
          <label className="relative top-3 left-1 text-[#929292] text-sm">Email</label>
          <br></br>
          <input className='h-11 w-[45vw] mr-36 mt-4 rounded-lg border border-[#D3D3D3] pl-3  focus:border-[#000 lg:mr-0 lg:w-[90vw]' name="user_email" placeholder=''></input>
          <br></br>
          <label className="relative top-1.5 left-1 text-[#929292] text-sm ">Name</label>
          <br></br>
          <input className='h-11 w-[45vw] mt-2 rounded-lg  border border-[#D3D3D3] pl-3  focus:border-[#000 lg:mr-0 lg:w-[90vw]' name="user_name" placeholder=''></input>
          <br></br>
          <label className="relative top-1.5 left-1 text-[#929292] text-sm ">Question</label>
          <br></br>
          <textarea className='h-56 w-[45vw] mt-2 rounded-lg border border-[#D3D3D3] pl-3  focus:border-[#000 lg:mr-0 lg:w-[90vw]' name="message" placeholder=''></textarea>
          <br></br>          
          <button className="text-white bg-[#088C61]  rounded-full w-[45vw] h-12 mt-4 mb-20 flex justify-center items-center lg:mr-0 lg:w-[90vw]" type="submit" value="Send" onClick={sendEmail}>Send message <span className='text-lg ml-2'><FiArrowRight/></span></button>
          <br></br>
          </div>
          </form>

        </div>

      </div>


        <div>
            <p className="text-3xl font-bold text-center mt-14 lg:text-2xl" id="title">Frequently asked questions</p>
        </div>

      <div className="flex justify-center w-full mt-12 mb-36">
          <div>
          <ContactAccord
          title="What is BerriBasket?"
          text="BerriBasket creates an online platform for farmers markets, allowing for farmer’s to add an additional selling channel and stream of revenue to their small businesses"
        />
        <ContactAccord
          title="Where is BerriBasket supported?"
          text="We are currently focusing on contacting farmers' markets in the Palo Alto, CA area to beta test BerriBasket"
        />
        <ContactAccord
          title="Where is the food sourced from?"
          text="All food on BerriBasket sourced locally from farmers in your area"
        />
        <ContactAccord
          title="How long does it take to get my food?"
          text="As soon as the farmer receives your order begins to be processed"
        />
        <ContactAccord
          title="When will my zipcode be supported?"
          text="We are working on expanding as far as the eye can see..."
        />      
          </div>
      </div>

<Footer/>
    
    </div>
  )
}
