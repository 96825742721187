let defaultState = {
  authenticated: false,
  displayedMarkets: {},
  displayedLocation: "",
  cart: []
};

const reducers = (state = defaultState, action) => {
  switch (action.type) {
    case "MARKETS":
      console.log(`Action payload: ${action.payload.displayedMarkets}`);
      return {
        ...state,
        displayedMarkets: action.payload.displayedMarkets,
        displayedLocation: action.payload.displayedLocation,
      };

    case "CART/addition":
      
      const existsInCart = state.cart.some(
        (item) => (item.uniqueid === action.payload.uniqueid)
      );

      if (existsInCart) {

        return {
          ...state,
          cart: state.cart.map((content) => {
            if (content.uniqueid === action.payload.uniqueid) {
              return {
                ...content,
                quantity: content.quantity + action.payload.quantity,
              };
            } else {
              return content;
            }
          }),
        };
      } else {
        return {
          ...state,
          cart: [
            ...state.cart,
            {
              name: action.payload.name,
              id: action.payload.id,
              quantity: action.payload.quantity,
              price: action.payload.price,
              image: action.payload.image,
              vendor: action.payload.vendor,
              vendorId: action.payload.vendorId,
              marketName: action.payload.marketName,
              marketId: action.payload.marketId,
              uniqueid: action.payload.uniqueid
            },
          ],
        };
      }

    case "CART/removal":
      return {
        ...state,
        cart: state.cart.filter((item) => ((item.uniqueid !== action.payload.uniqueid))),
      };

      case "AUTHENTICATION/change": return { ...state, authenticated: action.payload.authenticated, };


    default:
      return state;

    case "CART/clear":
      return {
        ...state,
        cart: []
      };
  }
};

export default reducers;
