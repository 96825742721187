import React, { useState, useEffect } from "react";
import { withRouter } from "../../comps/withRouter";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import Hamburger from '../../comps/Hamburger'
import Footer from "../../comps/Footer";
import Logo from '../../static/logo.webp'

class Article extends React.Component {
    constructor() {
        super();
      }
      
    render(){

   return <div>
            <div className="flex flex-row justify-center bg-[#fbfbfb] border-b">
      <div className="w-1/2">
      <Link to={"/"}>
              <h2 className="z-auto text-3xl text-[#000] font-bold m-8 mt-[30px] mb-6 cursor-pointer ml-36 lg:ml-4 relative sm:text-2xl sm:mt-[29px]" id="title">
                  Berri<span className="text-[#088C61] font-bold">Basket</span>
                </h2>
              </Link>
              </div>
              <div className="w-1/2 lg:flex lg:justify-end -mt-1 mr-4 md:mt-6">
                  <Hamburger color={" text-[#000] mr-16 hover:underline underline-offset-4"} bizbtn={" text-[#000] flex items-center z-[999]"} modal={"-ml-96 w-[30vw] bg-[#ffffff] border rounded-lg absolute mt-10 p-6 z-30"}/>
              </div>
        </div>

<div className="ml-36 mr-36 md:ml-4 md:mr-4">
        <div className="flex">
        <p className="flex items-center text-md mt-10 mb-0 text-[#929292] w-full md:text-sm"><img className="w-10 mr-4 border rounded-full md:w-8" src = {Logo}></img>Published by the BerriBasket Team <span className="ml-8">{this.props.router.location.state.publishedDate}</span></p>
    </div>
            <h1 className='flex font-extrabold text-6xl mt-10 mb-0 sm:text-4xl md:mt-6 leading-snug' id="title">{this.props.router.location.state.title}</h1>
            <div className="w-full">
                <img className="w-full h-[30rem] rounded-lg object-cover mt-10 object-top opacity-80" src = {this.props.router.location.state.coverImg}></img>
                <p className="text-[#929292] text-xs mt-2">{this.props.router.location.state.coverDesc}</p>
            </div>

            <div className="w-full text-[#000] p-24 pt-16 pb-0 md:p-8">
                <p className="leading-8">{this.props.router.location.state.intro}</p>
                <p className="font-semibold text-lg mt-16 leading-8">{this.props.router.location.state.subHeading1}</p>
                <p className="mt-4 leading-8">{this.props.router.location.state.text1}</p>
                <div>
                    <img className="mt-16 rounded-lg h-80 w-full object-cover" src = {this.props.router.location.state.img2}></img>
                    <p className="text-[#929292] text-xs mt-2">{this.props.router.location.state.img2Desc}</p>
                </div>
                <p className="font-semibold text-lg mt-16 leading-8">{this.props.router.location.state.subHeading2}</p>
                <p className="mt-4 leading-8">{this.props.router.location.state.text2}</p>
                <p className="font-semibold text-lg mt-16 leading-8">{this.props.router.location.state.subHeading3}</p>
                <p className="mt-4 leading-8">{this.props.router.location.state.text3}</p>
                <p className="font-semibold text-lg mt-16 leading-8">{this.props.router.location.state.subHeading4}</p>
                <p className="mt-4 leading-8">{this.props.router.location.state.text4}</p>
                <div className="flex mt-16 justify-center md:flex-wrap">
                    <div>
                        <img className="w-96 mr-10 rounded-lg md:mr-0 md:w-full" src = {this.props.router.location.state.img3}></img>
                        <p className="text-[#929292] text-xs mt-2">{this.props.router.location.state.img3Desc}</p>
                    </div>
                    <div>
                        <img className="w-96 rounded-lg md:mt-6 md:w-full" src = {this.props.router.location.state.img4}></img>
                        <p className="text-[#929292] text-xs mt-2">{this.props.router.location.state.img4Desc}</p>
                    </div>

                </div>
            </div>
        </div>

        <div>
            <Footer/>
        </div>
    
    </div>
 }
}
const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Article)
);
