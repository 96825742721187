import React, {useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Comps/Header";
import CartItems from "./Comps/CartItems";
import { FiArrowRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import groupBy from "lodash/groupBy";
import toast from "react-hot-toast";


export default function Cart() {
  let cartItems = useSelector((state) => state.reducers.cart);

  const dispatch = useDispatch()
  var isAuthenticated = useSelector((state) => state.reducers.authenticated);

  const [cart, setCart] = useState(false);
  let navigate = useNavigate()

  const toggleCart = () => {
    setCart(!cart);
  };

  const createOrder = () => {
    console.log(
      `Create order clicked with cartItems: ${JSON.stringify(cartItems)}`
    );
    var vendorProducts = groupBy(cartItems, "vendor");

    var request_obj = [];
    for (var key of Object.keys(vendorProducts)) {
      var vendorSpecifcDetails = {
        vendorId: "",
        marketId: "",
        orderItems: [],
      };

      var vendorProductsList = vendorProducts[key];
      vendorProductsList.forEach(function (arrayItem) {
        vendorSpecifcDetails["vendorId"] = arrayItem["vendorId"];
        vendorSpecifcDetails["marketId"] = arrayItem["marketId"];

        var orderItem = {
          quantity: arrayItem["quantity"],
          price: arrayItem["price"],
          item_id: arrayItem["id"],
        };
        vendorSpecifcDetails["orderItems"].push(orderItem);
      });
      request_obj.push(vendorSpecifcDetails);
    }

    var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
      "",
      "api/orders"
    );

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      credentials: 'include',
      authorization: "include",
      mode:'cors',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_obj),
    };
    if(cartItems.length > 0){
      if(isAuthenticated === true){
        fetch(endpoint, requestOptions)
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => {
          if (obj.status === 201) { 
            console.log(`Insertion of order was a success with body ${JSON.stringify(obj.body)}`)
            console.log('Navigating to checkout success')
            dispatch({ type: "CART/clear" });
            navigate("/checkout/success", {state: {orderNumber: obj.body.orderNumber, orderTotal: obj.body.orderTotal }})
          }
          else{
            console.log(`Insertion of order with response status: ${obj.status}`)
            toast("error creating order")
          }
        });
      }
      else if(isAuthenticated !== true){
        toast("Please sign into an account")
      }
    }
  };

  return (
  
    <div>
      <Header />
      <p>.</p>
      <div>
        <p className="flex font-bold text-4xl m-8 mt-8 ml-36 mb-0 sm:text-3xl sm:ml-8 mt-[6.5rem] md:mt-24">
          Baskets
        </p>
        <div className="flex justify-center mb-10">
          <div className="ml-12 h-[56vh] mt-6 w-3/5 overflow-scroll md:w-full md:ml-2">
            {cartItems.length <= 0 && (
              <div className="flex justify-center mt-28">
                <div>
                  <p className="text-[#929292]  md:ml-3">
                    No berris found in this basket
                  </p>
                  <Link to={"/"}>
                    <button className="text-[#088C61]  flex mt-3 md:ml-3">
                      Shop markets{" "}
                      <span className="ml-2 mt-[4px] text-lg">
                        <FiArrowRight />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            )}
            <CartItems />
          </div>
          <hr></hr>
        </div>
        <div className="flex justify-center mb-10 mt-0 w-full">
          <div>
            <button
              type="button"
              className="text-[#088C61]  rounded-lg h-12 flex justify-center items-center ml-2"
            >
              Continue shopping
              <span className="text-lg ml-2">
                <FiArrowRight />
              </span>
            </button>
            <div>
              <p className="text-[#929292]  text-center -ml-1">or</p>
            </div>
              <button
                type="button"
                className="text-white bg-[#088C61]  rounded-lg h-12 flex justify-center items-center w-40 mt-4 ml-3"
                onClick={createOrder}
              >
                Check out
                <span className="text-lg ml-2">
                  <FiArrowRight />
                </span>
              </button>
          </div>
        </div>
      </div>
    </div>
  );
}
