import React, { useState, useEffect } from "react";
import Cali from '../static/states/cali.svg'
import {Link} from "react-router-dom";
import { FiArrowDown } from "react-icons/fi";
import axios from 'axios';
import Hamburger from '../comps/Hamburger'
import Footer from "../comps/Footer";

export default function Zipcodes() {
    const [modal, setModal] = useState(false);
    const [markets, setMarkets] = useState([{zipcode:"No results found"}]);

    const toggleCali = () => {
      setModal(!modal);
    };
  

    const getZipcodes = () => {
        axios.get(`http://localhost:8080/api/markets`)
        .then(res => {
            setMarkets(res.data)
      })
      .catch((error) => console.log("error", error));
    }
    useEffect(() => {
        getZipcodes();
   }, []);


  return (
    <div>
    <div className="flex flex-row justify-center bg-[#fbfbfb] border-b">
      <div className="w-1/2">
      <Link to={"/"}>
              <h2 className="z-auto text-3xl text-[#000] font-bold m-8 mt-[30px] mb-6 cursor-pointer ml-36 lg:ml-4 relative sm:text-2xl sm:mt-[29px]" id="title">
                  Berri<span className="text-[#088C61] font-bold">Basket</span>
                </h2>
              </Link>
              </div>
              <div className="w-1/2 lg:flex lg:justify-end -mt-1 mr-4 md:mt-6">
                  <Hamburger color={" text-[#000] mr-16 hover:underline underline-offset-4"} bizbtn={" text-[#000] flex items-center z-[999]"} modal={"-ml-96 w-[30vw] bg-[#ffffff] border rounded-lg absolute mt-10 p-6 z-30"}/>
              </div>
        </div>

<div>
            <h1 className='flex font-extrabold text-5xl m-8 mt-8 ml-36 mb-0 sm:text-4xl lg:ml-4' id="title">Supported Zipcodes</h1>
            <h2 className='ml-36 text-[#929292] mt-6  lg:ml-4'>View all the areas that BerriBasket is supported</h2>
        </div>


        <div className='w-64 h-72 bg-[#ffffff] rounded-lg ml-36 mt-10 sm:ml-4' id="shadow">
            <p className='text-xl text-[#00000] font-bold m-6 mb-2 pt-5'>California </p>
            <div className='flex justify-center h-36'>
                <div>
                <img className='ml-7 mt-2 mb-4' alt="Californa state" src = {Cali}></img>
                </div>
            </div>
            <div className="flex justify-center">
            <button onClick={toggleCali} type="button" className="text-white bg-[#088C61]  rounded-full w-48 h-12 mt-4 flex justify-center items-center">View zipcodes<span className='text-lg ml-2'><FiArrowDown/></span></button>

            </div>
        </div>
    

        {modal && (
            <div className= "ml-36 mr-36 mt-14 rounded-lg mb-14 md:ml-10 md:mr-10" id="modal">
                    <div className="flex flex-wrap">
                    {markets.map((market) => {
              return (
                <div className="w-36 h-24 m-3">
                    <p className="font-semibold text-lg text-[#000]">{market["city"]}</p>
                    <p className=" text-[#929292] mt-1">{market["zipcode"]}</p>
                    </div>
              );
            })}
                    </div>
                    </div>
        )}

  <Footer/>
                    </div>
  );
}
