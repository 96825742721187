import React, { useState } from "react";
import CartIcon from "../../../static/cart2.svg";
import Close from "../../../static/close.svg";
import { useSelector } from "react-redux";
import CartItems from "./CartItems";
import { FiArrowRight } from "react-icons/fi";
import groupBy from "lodash/groupBy";
import {Link, useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux'
import toast from "react-hot-toast";

export default function Cart() {

  const dispatch = useDispatch()
  var isAuthenticated = useSelector((state) => state.reducers.authenticated);

  const [cart, setCart] = useState(false);
  let navigate = useNavigate()

  const toggleCart = () => {
    setCart(!cart);
  };

  const createOrder = () => {
    console.log(
      `Create order clicked with cartItems: ${JSON.stringify(cartItems)}`
    );
    var vendorProducts = groupBy(cartItems, "vendor");

    var request_obj = [];
    for (var key of Object.keys(vendorProducts)) {
      var vendorSpecifcDetails = {
        vendorId: "",
        marketId: "",
        orderItems: [],
      };

      var vendorProductsList = vendorProducts[key];
      vendorProductsList.forEach(function (arrayItem) {
        vendorSpecifcDetails["vendorId"] = arrayItem["vendorId"];
        vendorSpecifcDetails["marketId"] = arrayItem["marketId"];

        var orderItem = {
          quantity: arrayItem["quantity"],
          price: arrayItem["price"],
          item_id: arrayItem["id"],
        };
        vendorSpecifcDetails["orderItems"].push(orderItem);
      });
      request_obj.push(vendorSpecifcDetails);
    }

    var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
      "",
      "api/orders"
    );

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      credentials: 'include',
      authorization: "include",
      mode:'cors',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_obj),
    };
    if(cartItems.length > 0){
      if(isAuthenticated === true){
        fetch(endpoint, requestOptions)
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => {
          if (obj.status === 201) { 
            console.log(`Insertion of order was a success with body ${JSON.stringify(obj.body)}`)
            console.log('Navigating to checkout success')
            dispatch({ type: "CART/clear" });
            navigate("/checkout/success", {state: {orderNumber: obj.body.orderNumber, orderTotal: obj.body.orderTotal }})
          }
          else{
            console.log(`Insertion of order with response status: ${obj.status}`)
            toast("error creating order")
          }
        });
      }
      else if(isAuthenticated !== true){
        toast("Please sign into an account")
      }
    }
  


   
  };

  let cartItems = useSelector((state) => state.reducers.cart);

  return (
    <div>
      <button
        type="button"
        onClick={toggleCart}
        className="text-[#ffffff] text-xl bg-[#144444]  rounded-full m-8 w-20 h-12 ml-10 mr-36 mt-6 md:mr-8 flex justify-center items-center"
      >
        <span className="flex items-center">
          <img className="w-[1.3rem]" src={CartIcon}></img>
          <span className="ml-3 mt-1  text-[18px] text-[#ffffff]">
            {cartItems.length}
          </span>
        </span>
      </button>

      {cart && (
        <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="bg-[#00000020] w-full h-full fixed top-0 left-0 right-0 bottom-0 z-10"></div>
          <div
            className="fixed  top-2 right-2 bottom-2 rounded-lg bg-[#ffffff] z-30 shadow-2xl w-96 md:w-full md:right-0 md:bottom-0 md:top-6"
            id="cartModal"
          >
            <button
              onClick={toggleCart}
              className="absolute text-3xl top-4 right-4 text-[#929292]"
            >
              <img className="w-5" src={Close}></img>
            </button>
            <Link to={"/basket"}>
              <button className="text-2xl text-[#00000] font-bold m-9 cursor-pointer ml-7 mb-5 mt-7 ml-6 ">
                Basket
              </button>
            </Link>
            <hr></hr>

            <div className="w-full h-[70vh]  overflow-auto">
              {cartItems.length <= 0 && (
                <div className="flex justify-center mt-28">
                  <div>
                    <p className="text-[#929292]  md:ml-3">
                      No berris found in this basket
                    </p>
                    <Link to={"/"}>
                      <button className="text-[#088C61]  flex mt-3 md:ml-3">
                        Shop markets{" "}
                        <span className="ml-2 mt-[4px] text-lg">
                          <FiArrowRight />
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              )}
              <CartItems />
            </div>
            <div className="flex justify-center mb-10 mt-0 w-full">
              <div>
                <button
                  type="button"
                  className="text-[#088C61]  rounded-lg h-12 flex justify-center items-center ml-2"
                >
                  Continue shopping
                  <span className="text-lg ml-2">
                    <FiArrowRight />
                  </span>
                </button>
                <div>
                  <p className="text-[#929292]  text-center -ml-1">
                    or
                  </p>
                </div>
                {/* <Link to={"/checkout/success"}> */}
                <button
                  type="button"
                  className="text-white bg-[#088C61]  rounded-lg h-12 flex justify-center items-center w-40 mt-4 ml-3"
                  onClick={createOrder}
                >
                  Check out
                  <span className="text-lg ml-2">
                    <FiArrowRight />
                  </span>
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
