import React, {Suspense, lazy} from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../comps/withRouter";
import { connect } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import MarketIcon from "../../static/market.svg";
import Header from "./Comps/Header";
import Loading from '../../static/Loading'
import FarmImg from '../../static/farm.webp'
const Vendors = lazy(() => import('./Comps/Vendors')) //Suspense

class MarketInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      marketId: -1,
      displayedVendors: [],
      location: "",
    };
  }

  componentDidMount() {
    var marketId_ = this.props.router.location.state.marketId;
    console.log(`MarketId is: ${marketId_}`)
    this.setState({
      location: localStorage.getItem("location"),
    });
    
    if(process.env.REACT_APP_ENVIRONMENT == 'noDocker')
    {
      this.setState({
        marketId: 1,
        displayedVendors: [
          {
            "city": "San Mateo",
            "dayOfWeek": 6,
            "name": "Bay Area Bee's San Mateo",
            "vendor_id": 1
          },
          {
            "city": "Salinas",
            "dayOfWeek": 6,
            "name": "Bay Fresh Producer",
            "vendor_id": 2
          },
          {
            "city": "Fremont",
            "dayOfWeek": 2,
            "name": "Bunbao Inc.",
            "vendor_id": 3
          },
          {
            "city": "Hollister",
            "dayOfWeek": 5,
            "name": "Celio Farms",
            "vendor_id": 4
          },
          {
            "city": "Foster City",
            "dayOfWeek": 3,
            "name": "Dragon Baby Seafood",
            "vendor_id": 5
          },
          {
            "city": "Los Banos",
            "dayOfWeek": 6,
            "name": "Jacob's Farm",
            "vendor_id": 6
          },
          {
            "city": "Milpitas",
            "dayOfWeek": 1,
            "name": "Jaden's Kettle Corn",
            "vendor_id": 7
          },
          {
            "city": "fresno",
            "dayOfWeek": 1,
            "name": "K&G Farm",
            "vendor_id": 8
          },
          {
            "city": "Menlo Park",
            "dayOfWeek": 3,
            "name": "Little Sky Bakery",
            "vendor_id": 9
          },
          {
            "city": "Hughson",
            "dayOfWeek": 3,
            "name": "Lujan Farm",
            "vendor_id": 10
          },
          {
            "city": "Hughson",
            "dayOfWeek": 5,
            "name": "Resendiz Farms",
            "vendor_id": 11
          },
          {
            "city": "Oakland",
            "dayOfWeek": 3,
            "name": "Roli Roti Gourmet",
            "vendor_id": 12
          },
          {
            "city": "Belmont",
            "dayOfWeek": 1,
            "name": "WoKitchen",
            "vendor_id": 13
          }
        ]
      });
    }

   else if(process.env.REACT_APP_ENVIRONMENT == 'docker'){
    var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
      "",
      "api/vendors/",
      marketId_
    );
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    var vendorsAtMarket = [];

    fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      this.setState({
        marketId: marketId_,
        displayedVendors: result,
      });
      if (result.length >= 1) {
      } else {
        console.log(`Warning: didn't get any results back`);
      }
    })
    .catch((error) => console.log("error", error));
}
   }
   

  render() {

    return (
      <div>
        <Toaster
          toastOptions={{
            success: {
              iconTheme: {
                primary: "#088C61",
                secondary: "white",
              },
            },
          }}
        />

        <Header />
        <p>.</p>
        <div className="ml-36 mr-36 md:ml-8 md:mr-8">
          <div>
            <img
              width={"100%"}
              className="h-56 rounded object-cover mt-24"
              src={FarmImg}
            ></img>
            <div className="relative -mt-11 ml-4">
              <img width={"72px"} src={MarketIcon}></img>
            </div>
            <div className="flex justify-center">
              <div className="w-2/3 md:w-full">
                <p className="font-bold m-4 mt-4 mb-2 text-2xl md:ml-2 capitalize">
                  {this.props.router.location.state.marketName}
                </p>
                <p className=" m-4 mb-2 mt-4 text-[#929292] md:ml-2">
                  {this.props.router.location.state.marketAddress} ,{" "}
                  {this.props.router.location.state.marketCity}
                </p>
                <p className=" m-4 mt-0 text-[#929292] md:ml-2">
                  Market hours:{" "}
                  <span>
                    8-12 AM<span className="ml-3">-</span>{" "}
                    <span className="ml-2">$$$</span>
                  </span>
                </p>
              </div>
              <div className="w-1/3 flex justify-end">
                
              </div>
            </div>

            <hr className="mt-6 mb-0 md:mt-4"></hr>
          </div>

          <div>
            <div className="flex justify-start">
              <div className="w-5/6 md:w-full">
                <p className="font-bold m-4 mt-12 mb-2 text-2xl mt-0 md:ml-2 md:mb-4">
                  Vendors 
                </p>
              </div>
              <div className="w-1/6 flex justify-end md:mr-10">
                <p className="text-[#929292]  m-8 mb-12 mt-[54px] mb-1.5 mr-0">
                  {this.state.displayedVendors.length} <span className="md:hidden">vendors found</span>
                </p>
              </div>
            </div>
            <div className="inline-flex 3 w-full m-8 ml-2 mt-4 mr-0 h-auto overflow-scroll overflow-y-hidden h-64 mb-32">
            <Suspense fallback={<div className="mt-20 overflow-hidden -ml-14"><Loading/></div>}>
              {this.state.displayedVendors.map((vendor, index) => {
                return (
                  <Link
                    to={
                      `/vendorinfo?vendor=${vendor["name"]}=market` +
                      this.props.router.location.state.marketName
                    }
                    state={{
                      marketName: this.props.router.location.state.marketName,
                      marketId: this.state.marketId,
                      vendorName: vendor["name"],
                      vendorId: vendor["vendor_id"]
                    }}
                  >
                    <Vendors vendorName={vendor["name"]} key={index} />
                  </Link>
                );
              })}
            </Suspense>
            </div>
          </div>

          
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketInfo)
);