import React, {useState, Suspense, lazy} from "react";
import {useNavigate} from "react-router-dom";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { FiArrowRight, FiChevronDown } from "react-icons/fi";

export default function EditLocation() {
  const [edit, setEdit] = useState(false);
  const [autocomplete, setAutocomplete] = useState([""]);
  const [location, setLocation] = useState("")
  let navigate = useNavigate()
  const toggleEdit = () => {
    setEdit(!edit);
  };

  const ChangeLocation = () => {
    if(location !== "" && location !== localStorage.getItem("location")){
      localStorage.setItem("location", location)
      console.log(localStorage.getItem("location"))
      navigate("/landing")
    }
  }

  const handleonFocus = () => {
  var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
    "",
    "api/markets"
  );
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
    fetch(endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
     setAutocomplete(result)
     console.log("Found results for search")
    })
    .catch((error) => console.log("error", error));
}
 
    const handleOnSelect = (autocomplete) => {
      setLocation(autocomplete.city)
    }

  const formatResult = (autocomplete) => {
    return (
      <div>
          <span style={{ display: 'block', textAlign: 'left' }} className="cursor-pointer text-xs text-[#929292] ">Location</span>
          <span style={{ display: 'block', textAlign: 'left' }} className="cursor-pointer  text-base">{autocomplete.city}, {autocomplete.zipcode}</span>
      </div>
    )
  }
  return (
    
    <div>

        <button onClick={toggleEdit} className="text-[#929292] ml-2 mt-[.65rem] text-lg"><FiChevronDown/></button>

      {edit && (
      <div className="absolute  h-36 bg-[#ffffff] z-30 w-96 rounded-lg mt-2 md:left-2 border" id="modal">
        <p className='m-6 mt-5 text-xl mb-5'>Change location <span className='text-base text-[#929292] font-normal ml-2'>{localStorage.getItem("location")}</span></p>
        <div className='m-6 mt-0 font-medium'>

        <ReactSearchAutocomplete
            items={autocomplete}
            autoFocus
            formatResult={formatResult}
            onSelect={handleOnSelect}
            onFocus={handleonFocus}
            fuseOptions={{ keys: ["city", "zipcode", "marketid"] }}
            resultStringKeyName="city"
            placeholder="Enter new city or zipcode"
            maxResults={6}
            showClear={false}
            styling={
              {
                backgroundColor: "white",
                fontFamily: "Poppins",
                height: "44px",
                boxShadow: "",
              }
              }
          />
          </div>
          <div className="flex justify-end">
              <button type="button" onClick={ChangeLocation} className="text-white bg-[#088C61] font-medium rounded-full relative p-2 -top-16 mr-8"><FiArrowRight /></button>
            </div>
         
                   </div>
     )}

    </div>
  )
}
