import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { FiChevronDown } from "react-icons/fi";
import Signup from "./Signup.js";
import { Link, useNavigate } from "react-router-dom";
import Account from "../static/account.svg";
import Location from "../static/location2.svg";
import Help from "../static/help.svg";
import Close from "../static/close.svg";
import Biz from "../static/biz.svg";
import { FiArrowRight } from "react-icons/fi";
import Promo from '../static/promo.gif'


export default function Modal(props) {
  const [modal, setModal] = useState(false);
  const [biz, setBiz] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleBiz = () => {
    setBiz(!biz);
  };

  return (
    <div>
<div className="flex justify-center mt-[40px] md:hidden">
      <Link to={'/'}><h2 className={props.color} >Home</h2></Link>
      <Link to={'/zipcodes'}><h2 className={props.color}>Zipcodes</h2></Link>
      <Link to={'/contact'}><h2 className={props.color}>Contact</h2></Link>
      {biz && (
        <div className="">
          <div className="w-full p-3 right-0 left-0 top-0 bottom-0 bg-[#929292] backdrop-blur-sm bg-white/10 fixed z-10" id="modal"></div>
          <div className={props.modal} id="bizModal">
            <div className="">
              <img alt="BerriBasket Business Promotion" className="rounded-md h-40" src = {Promo}></img>
            </div>
            <div className="">
              <h2 className="text-xl font-semibold mt-6" id="title">BerriBasket Business</h2>
              <h3 className="text-sm text-[#929292] font-medium mt-2">Created for businesses to manage their online storefront, sell products, and see all berribasket.com orders in one place</h3>
              <button className="text-[#088C61] font-medium mt-6 mb-1 flex">Coming soon<span className="ml-2 mt-[4px] text-md"><FiArrowRight/></span></button>
            </div>
          </div>
        </div>
      )}
      <button className={props.bizbtn} onClick={toggleBiz}>Business <span className="ml-2 mt-[1px] text-xl z-[999]"><FiChevronDown/></span></button>
</div>

      <button
        onClick={toggleModal}
        className="text-4xl md:flex hidden"
      >
        <HiMenu />
      </button>

      {modal && (
        <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0 hidden md:block">
          <div className="bg-[#00000020] w-full h-full fixed top-0 left-0 right-0 bottom-0 z-10"></div>
          <div className="fixed bottom-0 rounded-md z-10 w-[100vw] md:w-full top-0" id="modal">
            <div className="w-full bg-[#ffffff] rounded-md shadow-2xl">
              <button onClick={toggleModal} className="absolute text-3xl top-3 text-[#929292] right-3"><img className="w-5" src = {Close}></img></button>
            <div className="h-5/6">
              <Link to={'/'}><p className="text-2xl text-[#00000] font-bold mt-0 cursor-pointer ml-7 mb-5 mt-6 ml-8 pt-7" id="title">Berri<span className='text-[#088C61]'>Basket</span></p></Link>
              <hr className=" border-r mt-0"></hr>
              <Link to={'/'}> <p className="m-8 mt-7 text-base cursor-pointer text-[#929292] font-medium">
                <span className="flex items-center">
                  <span className="mr-5 text-2xl">
                    <img className="w-6" src={Biz}></img>
                  </span>{" "}
                  Home
                </span>
              </p>
              </Link>
             <Link to={'/account'}> <p className="m-8 mt-7 text-base cursor-pointer text-[#929292] font-medium">
                <span className="flex items-center">
                  <span className="mr-5 text-2xl">
                    <img className="w-6" src={Account}></img>
                  </span>{" "}
                  Account
                </span>
              </p>
              </Link>
              <Link to={"/zipcodes"}>
                <p className="m-8 mt-8 text-base cursor-pointer text-[#929292] font-medium">
                  <span className="flex items-center">
                    <span className="mr-5 text-2xl">
                      <img className="w-6" src={Location}></img>
                    </span>{" "}
                     Zipcodes
                  </span>
                </p>
              </Link>
              <Link to={"/contact"}>
                <p className="m-8 mt-8 text-base cursor-pointer text-[#929292] font-medium">
                  <span className="flex items-center">
                    <span className="mr-5 text-2xl">
                      <img className="w-6" src={Help}></img>
                    </span>{" "}
                    Contact
                  </span>
                </p>
              </Link>
                <button className="m-8 mt-0 text-base cursor-pointer text-[#929292] font-medium mb-0" onClick={toggleBiz}>
                  <span className="flex items-center">
                    <span className="mr-5 text-2xl">
                      <img className="w-6 h-9" src={Biz}></img>
                    </span>{" "}
                    Add your Business
                    <span className="ml-2 mt-[2px] text-xl z-10"><FiChevronDown/></span>
                  </span>
                </button>

              {biz && (
        <div className="w-full">
          <div className="m-8 bg-[#ffffff] border rounded-md absolute p-6 z-30 mt-2" id="bizModal">
            <div className="">
              <img className="rounded-md h-40" src = {Promo}></img>
            </div>
            <div className="">
              <p className="text-xl font-semibold mt-6" id="title">BerriBasket Business</p>
              <p className="text-sm text-[#929292] font-medium mt-2">Created for businesses to manage their online storefront, sell products, and see all berribasket.com orders in one place</p>
              <button className="text-[#088C61] font-medium mt-4 mb-1 flex">Coming soon<span className="ml-2 mt-[4px] text-md"><FiArrowRight/></span></button>
            </div>
          </div>
        </div>
      )}
            </div>
            <div className="flex justify-center mt-8 -ml-8">
            <Signup signOut={"text-white bg-[#088C61] rounded-lg w-24 h-12 mb-8"}/>
            </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
