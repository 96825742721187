import { React, useState } from "react";
import { useSpring, animated } from "react-spring";
import { BsArrowDownCircleFill } from 'react-icons/bs';

function ContactAccord(props) {
  const [open, setOpen] = useState(false);
  let toggleHandler = (e) => {
    setOpen(!open);
  };


  const openAnimation = useSpring({
    from: { maxHeight: "25px" },
    to: { maxHeight: open ? "200px" : "25px" },
    config: { duration: "300" }
  });

  return (
    <animated.div className="p-8 pb-10 overflow-hidden cursor-pointer w-[55vw] border-b md:w-[90vw]" style={openAnimation}>
      <div onClick={toggleHandler}>
        <h2 className="text-[#000] font-bold pb-10 w-[24vw] truncate md:w-[55vw]">{props.title}</h2>
        <h2 className="text-2xl text-[#144444] -mt-[65px] ml-[50.5vw] md:ml-[72vw]"><BsArrowDownCircleFill/></h2>
      </div>
      <div className="mt-6">
      <h3 className=" text-[#929292]">{props.text}</h3>

      </div>
    </animated.div>
  );
}

export default ContactAccord;
