import React from 'react'
import {createStore, combineReducers} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import reducers from '../reducers/reducers.js'


const persistConfig = {
    key: 'root',
    storage: storage
  }

const persistedReducer = persistReducer(persistConfig,combineReducers({
    reducers
}))

const store = createStore( persistedReducer,
     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

const persistor = persistStore(store)

export { persistor, store}