import React, { useState } from 'react'
import {Link, useNavigate} from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe"

export default function Footer() {
  const url = "https://gmail.us13.list-manage.com/subscribe/post?u=b44b7d3d3273e2a0649ace50e&amp;id=2027d85f7b&amp;f_id=0092e3e2f0";
  const SimpleForm = () => <MailchimpSubscribe url={url}/>
  const [newsletterEmail, setNewsletterEmail] = useState("");

  const handleNewsletterEmail = (event) => {
    setNewsletterEmail(event.target.value);
  };

  return (
    <footer className='reltaive bottom-0 w-full mt-40'>
 <div className='bg-[#fbfbfb] border-t p-6 pl-0 pr-0'>
      <div className='flex mt-4 ml-36 mr-36 lg:ml-4 lg:mr-4 md:flex-wrap'>
        <div className='w-3/4 md:w-full'>
          <h2 className='text-2xl font-bold' id="title">Join BerriBasket's newsletter</h2>
          <h3 className='mt-2 text-[#929292] '>We will send a newsletter with updates and new releases to BerriBasket, no spam</h3>
        </div>
   
        <div className='w-1/4 md:w-full md:mt-10 flex justify-end md:justify-start'>
        <label className='text-[#929292] text-sm mt-4 mr-2'>Email</label>
              <div className='flex'>
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <div>
                      <form className='flex' onSubmit={(event) => {
                    event.preventDefault();
                    subscribe({ EMAIL: newsletterEmail });
                  }}>
                    <input className='h-11 w-64 mt-1 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000]' placeholder='berribasket@gmail.com' value={newsletterEmail} onChange={handleNewsletterEmail}></input>
                    <button className='text-white text-sm bg-[#088C61]  rounded-lg pr-3 pl-3 h-11 mt-1 ml-1 mb-0 flex justify-center items-center'>Join</button>
                    </form>
                    {status === "sending" && <div style={{ color: "#929292" }}>Adding to newsletter</div>}
                    {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{__html: message}}/>}
                    {status === "success" && <div style={{ color: "#088C61" }}>Subscribed!</div>}
                  </div>
                )}
                /> 
              </div>

        </div>
      </div>
      <hr className='ml-36 mr-36 mt-8 lg:ml-4 lg:mr-4'></hr>
                <div className="flex justify-center items-center">
                  <div className='flex items-center w-2/3'>
                  <Link to={'/'}><h3 className="m-10 text-base cursor-pointer text-[#929292] ml-36  lg:ml-4">© BerriBasket</h3></Link>
        
                  </div>
                  
                  <div className='flex items-center justify-end w-1/3'>
                    <Link to={'/'}><h3 className="m-10 text-base cursor-pointer text-[#929292] mr-4  sm:mr-2">Home</h3></Link>
                    <Link to={'/contact'}><h3 className="m-10 text-base cursor-pointer text-[#929292] mr-4  sm:mr-2">Contact</h3></Link>
                    <Link to={'/zipcodes'}><h3 className="m-10 text-base cursor-pointer text-[#929292] mr-36  lg:mr-4">Zipcodes</h3></Link>
                  </div>
                </div>
    </div>
  
    </footer>
   
  )
}
