import React from 'react'
import { Link } from "react-router-dom";
import Hamburger from "../../../comps/Hamburger";
import { MdLocationOn } from "react-icons/md";
import Cart from "./Cart";
import Edit from "./EditLocation";
import {useSelector} from 'react-redux'
import Signup from '../../../comps/Signup';

export default function Header() {

  let location = useSelector((state) => state.reducers.displayedLocation)
  
  return (
    <div>
        <div className="flex flex-row justify-center fixed w-full z-30 absolute">
          <div className="flex w-1/3 bg-[#fbfbfb] border-b h-[90px] md:w-1/6">
            <div className='hidden md:flex mt-6 ml-6'>
              <Hamburger />
            </div>
            <Link to={"/"}>
            <p className="z-auto text-3xl text-[#000] font-bold m-8 mt-[30px] mb-6 cursor-pointer ml-36 relative sm:ml-8 sm:text-2xl sm:mt-[26.5px] md:hidden" id="title">
                Berri<span className="text-[#088C61] font-bold">Basket</span>
              </p>
            </Link>
          </div>
          <div className="flex w-1/3 grow justify-center bg-[#fbfbfb] border-b h-[90px] md:w-4/6 md:justify-start">
            <p className="w-full mt-[26px] text-lg font-bold text-[#00000] flex justify-center md:justify-start md:ml-4">
              <span className="mt-1 mr-1 text-2xl text-[#000]">
                <MdLocationOn />
              </span>
              <span className="ml-1 mt-1 md:text-[16px]">{location}</span>
              <Edit/>
            </p>
          </div>
          <div className="w-1/3 flex justify-end items-center bg-[#fbfbfb] border-b h-[90px] md:w-2/6 lg:w-2/3">
            <div className='-mr-6 md:hidden'>
              <Signup signOut={"text-white bg-[#088C61] rounded-lg m-8 w-24 h-12 mt-6 ml-3"}/>
            </div>
           <Cart/>
          </div>
        </div>
    </div>
  )
}
