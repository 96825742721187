import React from "react";
import { withRouter } from "../comps/withRouter";
import { connect } from "react-redux";

class Checkout extends React.Component {
  constructor() {
    super();
    this.state = {
    
    };
  }

  componentDidMount() {
      console.log(`In checkout!!!`)
  }

  render() {

    return (
        <div>
            <hello/>
        </div>

    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);

