import React, {Suspense, lazy} from "react";
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Apple from "../../static/apple.webp";
import { withRouter } from "../../comps/withRouter";
import { connect } from "react-redux";
import Header from "./Comps/Header.js";
import toast, { Toaster } from "react-hot-toast";
import Loading from "../../static/Loading";
const Markets = lazy(() => import('./Comps/Markets.js')) //Suspense

class MarketplacePage extends React.Component {
  constructor() {
    super();
    this.state = {
      displayedMarkets: "",
      displayedLocation: "",
      showCount: 3
    };
  }

  notify = () => {
    toast("Already showing all markets");
  };

  showMore = () => {
    if(this.props.displayedMarkets.length < 3){
      this.notify()
    }
    else if(this.props.displayedMarkets.length === this.state.showCount){
      this.notify()
    }
    else{
      this.setState({
        showCount: this.props.displayedMarkets.length
      });
    }}

  componentDidMount() {
   
      
    console.log(
      `Loading Marketplace page with location props: ${this.props.displayedLocation}`
    );
    localStorage.setItem('location', this.props.displayedLocation)
  }

  render() {
    console.log(`Displayed Markets info: ${this.state.displayedMarkets}`);
    return (
      <div>
         <Toaster
          toastOptions={{
            error: {
              iconTheme: {
                primary: "#F06747",
                secondary: "white",
              },
            },
          }}
        />
        <Header/>

        <div className="flex justify-center w-full bg-[#F1F5D9] h-[310px] overflow-hidden">
          <div className="w-1/2 md:w-full mt-[5.5rem]">
            <p className="text-4xl font-bold m-20 ml-36 mb-6 mt-[64px] md:mt-14 md:m-8 md:text-3xl md:mt-12 md:mb-4">
              Local markets near you
            </p>
            <p className="m-20 ml-36 mt-0 text-[#929292]  md:ml-8">
              Yay, we found so many great markets near{" "}
              {this.props.displayedLocation}
            </p>
          </div>
          
          <div className="w-1/2 md:hidden">
            <p className="float-right -m-20 mr-12 mt-0 md:hidden -z-10">
              <img width={"80%"} className="-rotate-12 mt-20" src={Apple}></img>
            </p>
          </div>
        </div>

        <div className="flex justify-start">
          <div className="w-4/6 md:w-full">
            <p className="text-2xl font-bold m-8 mb-12 mt-10 ml-36 mb-1.5 md:ml-8">
             Top markets
            </p>
          </div>
          <div className="w-2/6 flex justify-end md:hidden">
            <p className="text-[#929292]  m-8 mb-12 mt-[44px] mb-1.5 mr-36 truncate">
              {this.props.displayedMarkets.length} markets found
            </p>
          </div>
          
        </div>
        <p className="ml-36 mb-4 text-[#929292]  md:ml-8">
          To order simply click on the market you are interested in
        </p>
        <div className="flex justify-center w-full min-w-full">
        <div className="inline-flex w-full m-8 ml-36 mt-0 mr-0 h-auto overflow-scroll overflow-y-hidden md:ml-7">
        <Suspense fallback={<div className="mt-20 overflow-hidden -ml-14"><Loading/></div>}>
            {this.props.displayedMarkets.slice(0, this.state.showCount).map((market, index) => {
              return (
                  <Link to={`/marketinfo?market=${market["name"]}`} state={{ 
                  marketId: market["market_id"], 
                  marketName: market['name'].toLowerCase(), 
                  marketAddress: market['address'],
                  marketCity: market['city']
                   }} 
                  >
                  <Markets
                    zipcode={market["zipcode"]}
                    marketName={market["name"].toLowerCase()}
                    address={market["address"]}
                    city={market["city"]}
                    marketid={market["market_id"]}
                    key={index}
                  />
                </Link>
                
              );
            })}
              {this.props.displayedMarkets.length > 3 &&
                <div className="flex">
                <div className="flex justify-center h-24 w-24 p-2 mb-6 flex mr-10 mt-20 ml-1">
                    <div className="h-full">
                    <div className="cursor-pointer" onClick={this.showMore}>
                        <BsFillArrowRightCircleFill className="text-4xl ml-3 mb-3 text-[#088C61]" />
                        <p className="text-[#929292] ">View all</p>
                      </div>
                    </div>
                  </div>
                </div>
            }
            </Suspense>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    displayedMarkets: state.reducers.displayedMarkets,
    displayedLocation: state.reducers.displayedLocation,
  };
};

const mapDispatchToProps = (dispatch) => {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketplacePage)
);

//withRouter(connect(mapStateToProps, mapDispatchToProps)(MarketplacePage));
