import React, { Suspense, lazy } from "react";
import { withRouter } from "../../comps/withRouter";
import Header from "./Comps/Header";
import { connect } from "react-redux";
import { FiX } from "react-icons/fi";
import { FaFilter } from "react-icons/fa";
import Fruit from "../../static/fruit.svg";
import Vegtable from "../../static/vegtable.svg";
import Chocolate from "../../static/chocolate.svg";
import Loading from "../../static/Loading";
import Close from '../../static/close.svg'
const VendorItem = lazy(() => import("./Comps/VendorItem")); //Suspense

class Vendorinfo extends React.Component {
  constructor() {
    super();
    this.state = {
      vendorProducts: [],
      filter: "",
      filterTF: false,
      modal: false,
    };
  }

  addFilter = (filterName) => {
    this.setState({
      filter: filterName,
      filterTF: true,
    });
  };

  removeFilter = () => {
    this.setState({
      filter: "",
      filterTF: false,
    });
  };

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  componentDidMount() {
    if(process.env.REACT_APP_ENVIRONMENT == 'noDocker'){
      this.setState({
        vendorId: 1,
        vendorProducts: [
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/artichoke_BXiDpFN6E.jpeg",
            "description": "",
            "name": "Artichokes",
            "product_id": 3,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/asparagus_EmN_rF1_A.jpeg",
            "description": "",
            "name": "Asparagus",
            "product_id": 25,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/beets_wLejOKd-r7.jpeg",
            "description": "",
            "name": "Beets",
            "product_id": 26,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/brusselssprouts_ZttKEjxDE.jpeg",
            "description": "",
            "name": "Brussels Sprouts",
            "product_id": 27,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/cabbage_-8DM8uYZZ.jpeg",
            "description": "",
            "name": "Cabbage",
            "product_id": 28,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/carrots_XU3Rifozu.jpeg",
            "description": "",
            "name": "Carrots",
            "product_id": 29,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/cauliflower_0oM33YTU4S.jpeg",
            "description": "",
            "name": "Cauliflower",
            "product_id": 30,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/celery_bA8Qian40G.jpeg",
            "description": "",
            "name": "Celery",
            "product_id": 31,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/cherries_G4UdPSiOeC.jpeg",
            "description": "",
            "name": "Cherries",
            "product_id": 15,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/corn_ebTYgio5N.jpeg",
            "description": "",
            "name": "Corn",
            "product_id": 32,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/cucumber_5HtsHfUXg.jpeg",
            "description": "",
            "name": "Cucumbers",
            "product_id": 5,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/eggplant_838WdhrOE.jpeg",
            "description": "",
            "name": "Eggplant",
            "product_id": 33,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/garlic_wUp2N18P6m.jpeg",
            "description": "",
            "name": "Garlic",
            "product_id": 7,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/herbs_HTfKZUVe-.jpeg",
            "description": "",
            "name": "Herbs",
            "product_id": 13,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/kale_S4_gGdJQA.jpeg",
            "description": "",
            "name": "Kale",
            "product_id": 34,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/lettuce_5miTQH9yM.jpeg",
            "description": "",
            "name": "Lettuce",
            "product_id": 35,
            "quantity_available": 0,
            "unit_price": 1,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/melon_UxibiWh-B.jpeg",
            "description": "",
            "name": "Melons",
            "product_id": 9,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/onion_ykoqxa1k4Y.jpeg",
            "description": "",
            "name": "Onions",
            "product_id": 11,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/peas_lP15Jpv7C.jpeg",
            "description": "",
            "name": "Peas",
            "product_id": 36,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/radishes_IGbV_fFLv.jpeg",
            "description": "",
            "name": "Radishes",
            "product_id": 21,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/strawberries_D5KPK66HG3.jpeg",
            "description": "",
            "name": "Strawberries",
            "product_id": 17,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/tomatoes_fZQ3uERsTh.jpeg",
            "description": "",
            "name": "Tomatoes",
            "product_id": 18,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/watermelon_4A2pbQF1P.jpeg",
            "description": "",
            "name": "Watermelon",
            "product_id": 37,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/sweetpotatoes_hIZXcPMULx.jpeg",
            "description": "",
            "name": "Sweet Potatoes",
            "product_id": 38,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/spinach_kRjQ4Vi1l-.jpeg",
            "description": "",
            "name": "Spinach",
            "product_id": 39,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/potatoes_BBmt5aVap.jpeg",
            "description": "",
            "name": "Potatoes",
            "product_id": 40,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/plums_TgZbdpaqe.jpeg",
            "description": "",
            "name": "Plums",
            "product_id": 12,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/peaches_oSp93HP2W-.jpeg",
            "description": "",
            "name": "Peaches",
            "product_id": 20,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/nectarine_crwmh2njXQ.jpeg",
            "description": "",
            "name": "Nectarines",
            "product_id": 10,
            "quantity_available": 0,
            "unit_price": 1,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/leeks_Fxk6GUq7b.jpeg",
            "description": "",
            "name": "Leeks",
            "product_id": 41,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Other",
            "custom_picture": null,
            "default_image": "/produce_images/fennel_3I2qmnia1.jpeg",
            "description": "",
            "name": "Fennel",
            "product_id": 42,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/cantaloupe_R5WPg4pXC.jpeg",
            "description": "",
            "name": "Cantaloupe",
            "product_id": 43,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/broccoli_QXQXDGa-3.jpeg",
            "description": "",
            "name": "Broccoli",
            "product_id": 44,
            "quantity_available": 0,
            "unit_price": 2,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/apricots_juLhp_fEH.jpeg",
            "description": "",
            "name": "Apricots",
            "product_id": 2,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/arugula_0mUJJjIok.jpeg",
            "description": "",
            "name": "Arugula",
            "product_id": 45,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/lemon_XfokQRlVX.jpeg",
            "description": "",
            "name": "Lemons",
            "product_id": 8,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/zucchini_nqNr8T0iq.jpeg",
            "description": "",
            "name": "Zucchini",
            "product_id": 19,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          },
          {
            "categoryname": "Vegetable",
            "custom_picture": null,
            "default_image": "/produce_images/favabeans_dFOSOd4sPb.jpeg",
            "description": "",
            "name": "Fava Beans",
            "product_id": 46,
            "quantity_available": 0,
            "unit_price": 5,
            "vendor_id": 6
          },
          {
            "categoryname": "Other",
            "custom_picture": null,
            "default_image": "/produce_images/honey_zWJSanv7T.jpeg",
            "description": "",
            "name": "Honey",
            "product_id": 1,
            "quantity_available": 0,
            "unit_price": 3,
            "vendor_id": 6
          },
          {
            "categoryname": "Fruit",
            "custom_picture": null,
            "default_image": "/produce_images/avocados_0FSzTy1ovb.jpeg",
            "description": "",
            "name": "Avocados",
            "product_id": 4,
            "quantity_available": 0,
            "unit_price": 4,
            "vendor_id": 6
          }
        ],
      });
    }

    else if(process.env.REACT_APP_ENVIRONMENT == 'docker'){
      var vendorId_ = this.props.router.location.state.vendorId;

      var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
        "",
        "api/vendors/products/",
        vendorId_
      );
  
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
  
      var vendor_products = [];
  
      fetch(endpoint, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(JSON.stringify(result))
          this.setState({
            vendorId: vendorId_,
            vendorProducts: result,
          });
          if (result.length >= 1) {
            console.log(this.props.router.location.state.marketName);
            console.log(
              `Returned results from products api with length: ${result.length}`
            );
          } else {
            console.log(`Warning: didn't get any results back`);
          }
        })
        .catch((error) => console.log("error", error));
    }
  
  }

  render() {
    return (
      <div>
        <Header />
        <p>.</p>
        <div className="mt-[66px] flex fixed w-full bg-[#ffffff] border-b absolute">
          <div className="ml-36 md:ml-8 w-[50vw]">
            <p className="mb-2 text-sm text-[#929292] mt-6">Shopping with</p>
            <p className="font-semibold text-2xl mb-6">
              {this.props.router.location.state.vendorName}
            </p>
          </div>
          <div>
            <div className="hidden md:flex md:w-[50vw] flex justify-end md:mr-8">
              <button className="p-3 text-[#929292] rounded-lg text-xl mt-11" onClick={this.toggleModal}>
                <FaFilter />
              </button>
            </div>
            
            <div className="mt-2 ml-24 flex mb-4 w-[50vw] flex justify-end mr-36 md:hidden">
            <p className="mt-1 text-sm text-[#929292]">Filter by</p>
              <div className="cursor-pointer">
                {this.state.filter === "Fruit" && (
                  <button
                    className="absolute ml-[100px] mt-4 text-xl text-[#ffffff]  rounded-full bg-[#088C61] flex justify-center z-30 p-1"
                    onClick={this.removeFilter}
                  >
                    <FiX />
                  </button>
                )}
                <div
                  onClick={() => this.addFilter("Fruit")}
                  className="flex border p-[1px] rounded-full bg-[#fbfbfb] mt-7"
                >
                  <img className="w-12 ml-2 -mt-1" src={Fruit}></img>
                  <p className="ml-1 mt-[11px] mr-5  text-[#929292]">
                    Fruit
                  </p>
                </div>
              </div>
              <div className="cursor-pointer ml-4">
                {this.state.filter === "Vegetable" && (
                  <button
                    className="absolute ml-40 mt-4 text-xl text-[#ffffff]  rounded-full bg-[#088C61] flex justify-center z-30 p-1"
                    onClick={this.removeFilter}
                  >
                    <FiX />
                  </button>
                )}
                <div
                  onClick={() => this.addFilter("Vegetable")}
                  className="flex border p-[1px] rounded-full bg-[#fbfbfb] mt-7"
                >
                  <img className="w-12 ml-2 -mt-1" src={Vegtable}></img>
                  <p className="ml-1 mt-[11px] mr-5  text-[#929292]">
                    Vegetables
                  </p>
                </div>
              </div>
              <div className="cursor-pointer ml-4">
                {this.state.filter === "Other" && (
                  <button
                    className="absolute ml-[110px] mt-4 text-xl text-[#ffffff]  rounded-full bg-[#088C61] flex justify-center z-30 p-1"
                    onClick={this.removeFilter}
                  >
                    <FiX />
                  </button>
                )}
                <div
                  onClick={() => this.addFilter("Other")}
                  className="flex border p-[1px] rounded-full bg-[#fbfbfb] mt-7"
                >
                  <img className="w-12 ml-2 -mt-1" src={Chocolate}></img>
                  <p className="ml-1 mt-[11px] mr-5  text-[#929292]">
                    Other
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Suspense
          fallback={
            <div className="mt-20 overflow-hidden -ml-36">
              <Loading />
            </div>
          }
        >
          <div className="mt-56">
            {this.state.filterTF == false && (
              <div>
                <div className="flex flex-wrap ml-36 mr-36 mt-16 md:mt-56 md:ml-0 md:mr-0 justify-center mb-10 overflow-x-hidden">
                  {this.state.vendorProducts.map((product) => {
                    return (
                      <VendorItem
                        itemName={product["name"]}
                        itemImage={product["default_image"]}
                        itemPrice={product["unit_price"]}
                        itemId={product["product_id"]}
                        vendor={this.props.router.location.state.vendorName}
                        vendorId={this.props.router.location.state.vendorId}
                        marketName={this.props.router.location.state.marketName}
                        marketId = {this.props.router.location.state.marketId}
                        uniqueId={"" + product["vendor_id"] + product["product_id"]}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {this.state.filterTF === true && (
              <div>
                <div className="flex flex-wrap ml-36 mt-16 md:mt-72 md:ml-0 md:justify-center md:w-[100vw] mb-10 overflow-x-hidden">
                  {this.state.vendorProducts
                    .filter((fruit) => fruit.categoryname === this.state.filter)
                    .map((filteredProduct) => (
                      <VendorItem
                        itemName={filteredProduct["name"]}
                        itemImage={filteredProduct["default_image"]}
                        itemPrice={filteredProduct["unit_price"]}
                        itemId={filteredProduct["product_id"]}
                        vendorName={this.props.router.location.state.vendorName}
                        vendorId={this.props.router.location.state.vendorId}
                        marketName={this.props.router.location.state.marketName}
                        marketId = {this.props.router.location.state.marketId}
                        uniqueId={
                          "" +
                          filteredProduct["vendor_id"] +
                          filteredProduct["product_id"]
                        }
                      />
                    ))}
                </div>
              </div>
            )}
          </div>
        </Suspense>

        {this.state.modal && (
                <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0 flex justify-center">
                  <div className="bg-[#00000020] w-full h-full fixed top-0 left-0 right-0 bottom-0 z-30"></div>
                  <div
                    className="fixed rounded-lg bg-[#ffffff] z-30 shadow-2xl w-96 mt-36"
                    id="mailinglist"
                  >
                    <button
                      onClick={this.toggleModal}
                      className="absolute text-3xl top-4 right-4 text-[#929292]"
                    >
                      <img className="w-5" src={Close}></img>
                    </button>
                    <div>
                      <p className="text-xl font-semibold m-4 ml-8 mt-6">
                        Add Filter
                      </p>
                      <hr className="mt-6"></hr>
                      <div className="cursor-pointer m-4"> 
                {this.state.filter === "Fruit" && (
                  <button
                    className="absolute ml-80 mt-2.5 text-xl text-[#ffffff]  rounded-full bg-[#088C61] flex justify-center z-30 p-1"
                    onClick={this.removeFilter}
                  >
                    <FiX />
                  </button>
                )}
                <div
                  onClick={() => this.addFilter("Fruit")}
                  className="flex border p-[1px] rounded-full bg-[#fbfbfb] mt-4"
                >
                  <img className="w-12 ml-2 -mt-1" src={Fruit}></img>
                  <p className="mt-[11px]  text-[#929292]">
                    Fruit
                  </p>
                </div>
              </div>
              <div className="cursor-pointer m-4">
                {this.state.filter === "Vegetable" && (
                  <button
                    className="absolute ml-80 mt-2.5 text-xl text-[#ffffff]  rounded-full bg-[#088C61] flex justify-center z-30 p-1"
                    onClick={this.removeFilter}
                  >
                    <FiX />
                  </button>
                )}
                <div
                  onClick={() => this.addFilter("Vegetable")}
                  className="flex border p-[1px] rounded-full bg-[#fbfbfb] mt-4"
                >
                  <img className="w-12 ml-2 -mt-1" src={Vegtable}></img>
                  <p className="ml-1 mt-[11px] mr-5  text-[#929292]">
                    Vegetables
                  </p>
                </div>
              </div>
              <div className="cursor-pointer mb-8 m-4">
                {this.state.filter === "Other" && (
                  <button
                    className="absolute ml-80 mt-2.5 text-xl text-[#ffffff]  rounded-full bg-[#088C61] flex justify-center z-30 p-1"
                    onClick={this.removeFilter}
                  >
                    <FiX />
                  </button>
                )}
                <div
                  onClick={() => this.addFilter("Other")}
                  className="flex border p-[1px] rounded-full bg-[#fbfbfb] mt-4"
                >
                  <img className="w-12 ml-2 -mt-1" src={Chocolate}></img>
                  <p className="ml-1 mt-[11px] mr-5  text-[#929292]">
                    Other
                  </p>
                </div>
              </div>
                  
                   
                    </div>
                  </div>
                </div>
              )}
      
      </div>
    );
  }
}

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Vendorinfo)
);
