import React from 'react'
import Landing from './Landing'
import Account from './Account'
import {useSelector} from 'react-redux'

export default function Check() {
    let authed = useSelector((state) => state.reducers.authenticated)
  return (
    <div>
       {authed === false &&
       <Landing/>
        }
        {authed !== false &&
       <Account/>
        }
    </div>
  )
}
