import React from "react";
import Header from "./Comps/Header";
import CartItems from "./Comps/CartItems";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import Confetti from 'react-confetti'

export default function CheckoutSuccess(props) {
  const location = useLocation();

  return (
    <div>
        <Confetti recycle={false} className='lg:hidden'/>
      <Header />
      <div className="flex justify-center mt-36 absolute w-full">
        <div className="w-1/3 text-center -ml-4 md:w-2/3">
          <p className="text-3xl font-semibold">
            Rodger that, we got your order
          </p>
          <p className="mt-4 text-lg text-[#929292] ">
            Order #{location.state.orderNumber}
          </p>
          <div className="flex justify-center mt-6 mb-6">
            <QRCode
              size={256}
              className="w-32 h-32 rounded-lg"
              value={"http://127.0.0.1:3001/vendor/orderdetails?"+location.state.orderNumber}
              viewBox={`0 0 256 256`}
            />
          </div>

          <p className="mb-6 text-sm text-[#929292]">
            *Show this QR code to the vendor to pick up your order
          </p>
          <hr></hr>
          <p className="text-left font-semibold mt-6">Order Summary</p>
          <div className="block">
            <CartItems hideRemoval={true} />
          </div>
          <div className="flex mt-6 items-center">
            <div className="w-2/3 text-left">
              <p className="text-[#929292]">Order total</p>
            </div>
            <div className="w-2/3 text-right text-xl ">
              <p>${location.state.orderTotal}</p>
            </div>
          </div>
          <button className="mt-6  text-[#088C61] mb-24">
            Contact vendor
          </button>
        </div>
      </div>
    </div>
  );
}
