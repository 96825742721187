import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Close from "../static/close.svg";
import { MdOutlineError } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import PasswordStrengthBar from "react-password-strength-bar";
import {useSelector} from 'react-redux'
import { useDispatch } from "react-redux";

export default function Signup(props) {
  const [signup, setSignUp] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [erorrMsg, setErrorMsg] = useState("Please fill out this feild");
  const [erorrMsgPw, setErrorMsgPw] = useState("Please fill out this feild");
  const [erorrMsgPw2, setErrorMsgPw2] = useState("Please fill out this feild");
  const [erorrMsgPw3, setErrorMsgPw3] = useState("Please fill out this feild");
  const [passwordEye, setPasswordEye] = useState("password");
  const [passwordText, setPasswordText] = useState("Show");
  const [firstNameValueError, setFirstNameValueError] = useState(false);
  const [lastNameValueError, setLastNameValueError] = useState(false);
  const [emailValueError, setEmailValueError] = useState(false);
  const [passwordValueError, setPasswordValueError] = useState(false);
  const [passwordValueError2, setPasswordValueError2] = useState(false);
  const [emailValueError2, setEmailValueError2] = useState(false);
  const [verifyPasswordValueError, setVerifyPasswordValueError] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [verifyPasswordValue, setVerifyPasswordValue] = useState("");
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const dispatch = useDispatch();
  var isAuthenticated = useSelector((state) => state.reducers.authenticated);
  console.log(isAuthenticated)


  const signOut = () => {
    dispatch({ type: "AUTHENTICATION/change", payload: { authenticated: false} });
    window.location.reload()
  };

  const toggleSignup = () => {
    setSignUp(!signup);
  };

  const toggleSignin = () => {
    setSignIn(!signIn);
  };

  const toggleEye = () => {
    if (passwordEye === "password") {
      setPasswordEye("text");
      setPasswordText("Hide");
    } else if (passwordEye === "text") {
      setPasswordEye("password");
      setPasswordText("Show");
    }
  };

  function SignIn() {
    setSignUp(false);
    setSignIn(true);
  }

  function createOne() {
    setSignUp(true);
    setSignIn(false);
  }

  const handleSignInEmailChange = (event) => {
    setSignInEmail(event.target.value);
    if (emailValueError2 === true) {
      if (event.target.value.length > 0) {
        setEmailValueError2(false);
      }
    }
  };

  const handleSignInPasswordChange = (event) => {
    setSignInPassword(event.target.value);
    if (passwordValueError2 === true) {
      if (event.target.value.length > 0) {
        setPasswordValueError2(false);
      }
    }
  };
  const handleFirstNameChange = (event) => {
    setFirstNameValue(event.target.value);
    if (firstNameValueError === true) {
      if (event.target.value.length > 0) {
        setFirstNameValueError(false);
      }
    }
  };

  const handleLastNameChange = (event) => {
    setLastNameValue(event.target.value);
    if(event.target.length >= 0){
      setLastNameValueError(true)
    }
  };

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
    if (emailValueError === true) {
      if (event.target.value.length > 0) {
        setEmailValueError(false);
      }
    }

  };
  const handlePasswordChange = (event) => {
    setPasswordValue(event.target.value);
    if (passwordValueError === true) {
      if (event.target.value.length > 0) {
        setPasswordValueError(false);
      }
    }
  };
  const handleVerifyPasswordChange = (event) => {
    setVerifyPasswordValue(event.target.value);
    if (verifyPasswordValueError === true) {
      if (event.target.value.length > 0) {
        setVerifyPasswordValueError(false);
      }
    }
  };

  const handleSignUpClick = () => {
    firstNameValue.length === 0 ? setFirstNameValueError(true) : setFirstNameValueError(false);
    emailValue.length === 0
      ? setEmailValueError(true)
      : setEmailValueError(false);
    passwordValue.length === 0
      ? setPasswordValueError(true)
      : setPasswordValueError(false);
    verifyPasswordValue.length === 0
      ? setVerifyPasswordValueError(true)
      : setVerifyPasswordValueError(false);

    if (!firstNameValue || !emailValue || !passwordValue || !verifyPasswordValue) {
      return;
    } else if (passwordValue !== verifyPasswordValue) {
      setPasswordValueError(true);
      setVerifyPasswordValueError(true);
      setErrorMsgPw("Passwords must match");
      return;
    }

    var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
      "",
      "api/auth/register"
    );

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: firstNameValue,
        email: emailValue,
        password: passwordValue,
        siteType: 'customer',
        role: "customer"
      }),
    };

    fetch(endpoint, requestOptions)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        if (obj.body.message === "SuccessfulRegistration") {
          toast("Sign up successful, Please sign in");
          setSignUp(false);
          setSignIn(true);
        } else if (obj.body.message === "InvalidEmail") {
          setEmailValueError(true);
          setErrorMsg("Please enter a vaild email");
        } else if (obj.body.message === "UserAlreadyExists") {
          setEmailValueError(true);
          setErrorMsg("Account already registered");
        } else {
          setErrorMsg("Please fill out this feild");
          toast(obj.body.message);
        }
      });

    //If no first and last name API will return status message indicating, then set name error to false and add error message

    //check for required fields

    //check for matching passwords
  };

  function handleLoginClick() {
    if(signInEmail === ""){
      setEmailValueError2(true)
      return
    }
    else if (signInEmail !== ""){
      setEmailValueError2(false)
    }
    if(signInPassword === ""){
      setPasswordValueError2(true)
      return
    }
    else if (signInPassword !== ""){
      setPasswordValueError2(false)
    }
    
    console.log(`Sign in email: ${signInEmail} and password ${signInPassword}`);

    var endpoint = process.env.REACT_APP_API_ENDPOINT.concat(
      "",
      "api/auth/login"
    );

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      mode: "cors",
      credentials: 'include',
      authorization: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: signInEmail,
        password: signInPassword,
        siteType: 'customer',
        role: "customer"
      }),
    };

    fetch(endpoint, requestOptions)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        if(obj.body.message === "The username and/or password are incorrect"){
          setEmailValueError2(true)
          setPasswordValueError2(true)
          setErrorMsgPw2("Incorrect password")
          setErrorMsgPw3("Incorrect email")
        }
        
        if (obj.status !== 200) {
          console.log(
            `Error with sign in - message body: ${obj.body.message} and status: ${obj.status}`
          );
          setEmailValueError2(true)
          setPasswordValueError2(true)
          setErrorMsgPw2("Incorrect password")
          setErrorMsgPw3("Incorrect email")
          return;
        } else {
          console.log(`Successfully signed in user`);
          dispatch({ type: "AUTHENTICATION/change", payload: { authenticated: true} });
          setSignIn(false)
          return;
        }
      });
  }
  return (
    
    <div>
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#088C61",
              secondary: "white",
            },
          },
        }}
      />
      
      <div
      className={
        !isAuthenticated
          ? "flex"
          : "hidden"
      }
      >
      <button
        onClick={toggleSignin}
        type="button"
        className="text-[#ffffff] bg-[#144444] rounded-lg w-24 h-12 m-8 mr-0 mt-6"
      >
        Sign in
      </button>
      <button
        onClick={toggleSignup}
        type="button"
        className="text-white bg-[#088C61]  rounded-lg m-8 w-24 h-12 mt-6 ml-3 mr-0"
      >
        Sign up
      </button>
      </div>

      <div  className={
                      !isAuthenticated
                        ? "hidden"
                        : "flex "
                    }>
      <button
        onClick={signOut}
        type="button"
        className={props.signOut}
      >
        Sign out
      </button>
      </div>
 


      {signup && (
        <div className="w-full h-full absolute top-0 left-0">
          <div className="flex justify-center">
            <div className="bg-[#00000020] w-full h-full fixed top-0 left-0 right-0 bottom-0 z-10"></div>

            <div
              className="fixed  w-[390px] bg-[#ffffff] z-30 mt-24 rounded-lg shadow-2xl md:w-[410px] md:mt-48 sm:bottom-0 sm:fixed sm:mt-0 md:w-[98vw] md:bottom-0"
              id="mailinglist"
            >
              <button
                onClick={toggleSignup}
                className="absolute text-3xl top-4 right-4 text-[#929292]"
              >
                <img className="w-5" src={Close}></img>
              </button>

              <div className="flex justify-center ml-[12px]">
                <div className="-ml-4">
                  <p className="font-semibold text-2xl mt-7 mb-2">Sign up</p>

                  <label className="relative top-3 left-1 text-[#929292] text-sm">
                    First Name
                  </label>
                  <br></br>
                  <input
                    className={
                      !firstNameValueError
                        ? "h-11 w-80 mt-4 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000]"
                        : "h-11 w-80 mt-4 border rounded-lg border-[#EF6747] pl-3  focus:border-[#EF6747]"
                    }
                    placeholder=""
                    value={firstNameValue}
                    onChange={handleFirstNameChange}
                  ></input>
                  <p
                    className={
                      !firstNameValueError
                        ? "hidden"
                        : "flex text-sm text-[#EF6747]  -mb-6 ml-1"
                    }
                  >
                    <span className="m-1 -mt-[32px] mr-2 ml-0 text-xl absolute ml-72">
                      <MdOutlineError />
                    </span>
                    Please fill out this feild
                  </p>
                  <br></br>

                  <label className="relative top-3 left-1 text-[#929292] text-sm">
                    Last name
                  </label>
                  <br></br>
                  <input
                    className={
                      !lastNameValueError
                        ? "h-11 w-80 mt-4 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000]"
                        : "h-11 w-80 mt-4 border rounded-lg border-[#EF6747] pl-3  focus:border-[#EF6747]"
                    }
                    placeholder=""
                    value={lastNameValue}
                    onChange={handleLastNameChange}
                  ></input>
                  <p
                    className={
                      !lastNameValueError
                        ? "hidden"
                        : "flex text-sm text-[#EF6747]  -mb-6 ml-1"
                    }
                  >
                    <span className="m-1 -mt-[32px] mr-2 ml-0 text-xl absolute ml-72">
                      <MdOutlineError />
                    </span>
                    Please fill out this feild
                  </p>
                  <br></br>

                  
                  <label className="relative top-1.5 left-1 text-[#929292] text-sm">
                    Email
                  </label>

                  <br></br>
                  <input
                    className={
                      !emailValueError
                        ? "h-11 w-80 mt-2 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000] -mb-10"
                        : "h-11 w-80 mt-2 border rounded-lg border-[#EF6747] pl-3  focus:border-[#EF6747]"
                    }
                    placeholder=""
                    value={emailValue}
                    onChange={handleEmailChange}
                  ></input>
               
                  <p
                    className={
                      !emailValueError
                        ? "hidden"
                        : "flex text-sm text-[#EF6747]  -mb-6 ml-1"
                    }
                  >
                    <span className="m-1 -mt-[32px] mr-2 ml-0 text-xl absolute ml-72">
                      <MdOutlineError />
                    </span>
                    {erorrMsg}
                  </p>
                  <br></br>
                  <label className="relative top-1.5 left-1 text-[#929292] text-sm">
                    Password
                  </label>
                  <br></br>
                  <input
                    className={
                      !passwordValueError
                        ? "h-11 w-80 mt-2 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000]"
                        : "h-11 w-80 mt-2 border rounded-lg border-[#EF6747] pl-3  focus:border-[#EF6747]"
                    }
                    placeholder=""
                    value={passwordValue}
                    onChange={handlePasswordChange}
                    type={passwordEye}
                    // type="password"
                  ></input>
                  <button
                    onClick={toggleEye}
                    className="absolute text-sm -mt-[15px] right-10"
                  >
                    {passwordText}
                  </button>

                  <p
                    className={
                      !passwordValueError
                        ? "hidden"
                        : "flex text-sm text-[#EF6747]  mb-3 ml-1"
                    }
                  >
                    <span className="m-1 -mt-[32px] mr-2 ml-0 text-xl absolute ml-72 ">
                      <MdOutlineError />
                    </span>
                    {erorrMsgPw}
                  </p>
                  <PasswordStrengthBar
                    className="-mb-9 mt-3 relative"
                    password={passwordValue}
                  />

                  <br></br>
                  <label className="relative top-1.5 left-1 text-[#929292] text-sm">
                    Verify password
                  </label>
                  <br></br>
                  <input
                    className={
                      !verifyPasswordValueError
                        ? "h-11 w-80 mt-2 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000]"
                        : "h-11 w-80 mt-2 border rounded-lg border-[#EF6747] pl-3  focus:border-[#EF6747]"
                    }
                    placeholder=""
                    type={passwordEye}
                    value={verifyPasswordValue}
                    onChange={handleVerifyPasswordChange}
                    // type="password"
                  ></input>
                  <p
                    className={
                      !verifyPasswordValueError
                        ? "hidden"
                        : "flex text-sm text-[#EF6747]  -mb-6 ml-1"
                    }
                  >
                    <span className="m-1 -mt-[32px] mr-2 ml-0 text-xl absolute ml-72">
                      <MdOutlineError />
                    </span>
                    {erorrMsgPw}
                  </p>
                  <br></br>
                  <button
                    type="button"
                    className="text-white bg-[#088C61]  rounded-lg w-80 h-12 mt-6 flex justify-center items-center"
                    onClick={handleSignUpClick}
                  >
                    Sign up{" "}
                  </button>
                  <br></br>
                  <p className="mb-7 text-center  text-[#929292]">
                    Already have an account?{" "}
                    <button onClick={SignIn}>
                      <span className="text-[#088C61]">Sign in</span>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {signIn && (
        <div className="w-full h-full absolute top-0 left-0">
          <div className="flex justify-center">
            <div className="bg-[#00000020] w-full h-full fixed top-0 left-0 right-0 bottom-0 z-10"></div>
            <div
              className="fixed  w-[390px] bg-[#ffffff] z-[999] mt-24 rounded-lg shadow-2xl md:w-[410px] md:mt-48 sm:bottom-0 sm:fixed sm:mt-0 md:w-[98vw] md:bottom-0"
              id="mailinglist"
            >
              <button
                onClick={toggleSignin}
                className="absolute text-3xl top-4 right-4 text-[#929292]"
              >
                <img className="w-5" src={Close}></img>
              </button>
              <div className="flex justify-center ml-[12px]">
                <div className="-ml-4">
                  <p className="font-semibold text-2xl mt-7 mb-2">Sign in</p>
                  <label className="relative top-3 left-1 text-[#929292] text-sm">
                    Email
                  </label>
                  <br></br>
                  <input
                   className={
                    !emailValueError2
                      ? "h-11 w-80 mt-4 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000]"
                      : "h-11 w-80 mt-4 border rounded-lg border-[#EF6747] pl-3  focus:border-[#EF6747]"
                  }
                  placeholder=""
                  value={signInEmail}
                  onChange={handleSignInEmailChange}
                ></input>
                <p
                  className={
                    !emailValueError2
                      ? "hidden"
                      : "flex text-sm text-[#EF6747]  -mb-6 ml-1"
                  }
                >
                  <span className="m-1 -mt-[32px] mr-2 ml-0 text-xl absolute ml-72">
                    <MdOutlineError />
                  </span>
                  {erorrMsgPw3}
                </p>
                  <br></br>
                  <label className="relative top-1.5 left-1 text-[#929292] text-sm ">
                    Password
                  </label>
                  <br></br>
                  <input
                   className={
                    !passwordValueError2
                      ? "h-11 w-80 mt-4 border rounded-lg border-[#D3D3D3] pl-3  focus:border-[#000]"
                      : "h-11 w-80 mt-4 border rounded-lg border-[#EF6747] pl-3  focus:border-[#EF6747]"
                  }
                  placeholder=""
                  type={"password"}
                  value={signInPassword}
                  onChange={handleSignInPasswordChange}
                ></input>
                <p
                  className={
                    !passwordValueError2
                      ? "hidden"
                      : "flex text-sm text-[#EF6747]  -mb-6 ml-1"
                  }
                >
                  <span className="m-1 -mt-[32px] mr-2 ml-0 text-xl absolute ml-72">
                    <MdOutlineError />
                  </span>
                  {erorrMsgPw2}
                </p>
                  <br></br>
                  <button
                    onClick={handleLoginClick}
                    type="button"
                    className="text-white bg-[#088C61]  rounded-lg w-80 h-12 mt-6 mb-0 flex justify-center items-center"
                  >
                    Sign in
                  </button>
                  <br></br>
                  <p className="mb-7 text-center font-medium text-[#929292]">
                    Don't have an account? <span className="text-[#088C61]"><button onClick={createOne}>Create one</button></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
