import React from 'react'
import Landing from './Landing'
import Marketplace from './Market/Marketplace'
import {useSelector} from 'react-redux'

export default function Check() {
    let location = useSelector((state) => state.reducers.displayedLocation)
  return (
    <div>
       {location !== "" &&
       <Marketplace/>
        }
        {location === "" &&
       <Landing/>
        }
    </div>
  )
}
