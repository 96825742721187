import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IKImage, IKContext, IKUpload } from "imagekitio-react";
import { FiArrowRight } from "react-icons/fi";
import { useDispatch } from "react-redux";
import Close from "../../../static/close.svg";
import groupBy from "lodash/groupBy";
import toast, { Toaster } from "react-hot-toast";

export default function CartItems(props) {
  let cartItems = useSelector((state) => state.reducers.cart);
  const dispatch = useDispatch();
  const [remove, setRemove] = useState(true);

  const handleBasketRemove = (itemId) => {
    console.log(`Handle basket remove with itemId: ${itemId}`);
    dispatch({ type: "CART/removal", payload: { uniqueid: itemId} });
    toast("Removed from basket");
    console.log("Removed item from cart")
  };

  const groups = groupBy(cartItems, "vendor");

  const setRemoveItem = () => {
    if(window.location.href === "http://127.0.0.1:3000/checkout/success"){
      setRemove(false)
      console.log(window.location.href)
    }
  }
  useEffect(() => {

    setRemoveItem();
}, []);

 
  return Object.keys(groups).map((market) => (
    <div className="overflow-x-hidden">
      <p className="mb-1 m-8 text-sm text-[#929292] mt-4 ml-7">Vendor</p>
      <p className="font-semibold text-lg m-8 mt-0 ml-7">{market}</p>
      {groups[market].map((item) => (
        <div>
           <Toaster
        toastOptions={{
          error: {
            iconTheme: {
              primary: "#F06747",
              secondary: "white",
            },
          },
        }}
      />
          <div className="flex m-2 mt-6 border-b-[1px]">
            <div className="h-16 w-1/4 flex justify-end object-cover ml-4 flex justify-center mb-8">
              <IKContext urlEndpoint="https://ik.imagekit.io/k7pimfaav">
                <IKImage path={item.image || ""} />
              </IKContext>
            </div>
            <div className="ml-8 w-2/4">
              <p className="text-lg font-semibold truncate w-80">{item.name}</p>
              <div className="flex">
                <p className="mt-1  text-[#929292]">
                  <span className="text-sm mr-1 text-[#929292]">Price:</span> $
                  {item.price} <span className="mr-0 ml-3 font-thin">|</span>
                </p>
                <p className="mt-1  ml-3 text-[#929292]">
                  <span className="text-sm mr-1 text-[#929292]">Quantity:</span>{" "}
                  {item.quantity}
                </p>
              </div>
            </div>
            {!props.hideRemoval && (<div className="w-1/4 flex justify-end mr-3">
            {remove && (
              <button onClick={() => handleBasketRemove(item.uniqueid)}>
                <img className="w-4 -mt-11 ml-10" src={Close}></img>
                <p className="text-[#929292] text-sm mt-1">Remove</p>
              </button>
            )}

            </div> )}
   
          </div>
        </div>
      ))}
    </div>
  ));
}

